import { API } from "aws-amplify";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { getProjectDetails } from "actions/projects";

export const GET_CURTAILMENT_TIMING_DOWNLOAD_TEMPLATE_REQUEST =
    "@@curtailmentTiming/GET_CURTAILMENT_TIMING_DOWNLOAD_TEMPLATE_REQUEST";
export const GET_CURTAILMENT_TIMING_DOWNLOAD_TEMPLATE_SUCCESS =
    "@@curtailmentTiming/GET_CURTAILMENT_TIMING_DOWNLOAD_TEMPLATE_SUCCESS";
export const GET_CURTAILMENT_TIMING_DOWNLOAD_TEMPLATE_FAILURE =
    "@@curtailmentTiming/GET_CURTAILMENT_TIMING_DOWNLOAD_TEMPLATE_FAILURE";

export function getCurtailmentTimingDownloadTemplate(
    id = "",
    organisation,
    sample_table = true
) {
    return async (dispatch) => {
        if (!(id && organisation)) return;
        dispatch({
            type: GET_CURTAILMENT_TIMING_DOWNLOAD_TEMPLATE_REQUEST,
        });
        try {
            let url = "/showdata";
            if (id && organisation) {
                url = `${url}?project_id=${id}&table_name=in_curtailment_timing&organisation=${organisation}&sample_table=${sample_table}`;
            }

            const response = await API.get("usimAPI", url);
            dispatch({
                type: GET_CURTAILMENT_TIMING_DOWNLOAD_TEMPLATE_SUCCESS,
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: GET_CURTAILMENT_TIMING_DOWNLOAD_TEMPLATE_FAILURE,
                payload: error,
            });
        }
    };
}

export const GET_CURTAILMENT_TIMING_LIST_REQUEST =
    "@@curtailmentTiming/GET_CURTAILMENT_TIMING_LIST_REQUEST";
export const GET_CURTAILMENT_TIMING_LIST_SUCCESS =
    "@@curtailmentTiming/GET_CURTAILMENT_TIMING_LIST_SUCCESS";
export const GET_CURTAILMENT_TIMING_LIST_FAILURE =
    "@@curtailmentTiming/GET_CURTAILMENT_TIMING_LIST_FAILURE";

export function getCurtailmentTimingList(id = "", organisation) {
    return async (dispatch) => {
        if (!(id && organisation)) return;
        dispatch({
            type: GET_CURTAILMENT_TIMING_LIST_REQUEST,
        });
        try {
            let url = "/showdata";
            if (id && organisation) {
                url = `${url}?project_id=${id}&table_name=in_curtailment_timing&organisation=${organisation}&sample_table=false`;
            }

            const response = await API.get("usimAPI", url);
            dispatch({
                type: GET_CURTAILMENT_TIMING_LIST_SUCCESS,
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: GET_CURTAILMENT_TIMING_LIST_FAILURE,
                payload: error?.response?.data,
            });
        }
    };
}

export const DELETE_CURTAILMENT_TIMING_LIST_REQUEST =
    "@@curtailmentTiming/DELETE_CURTAILMENT_TIMING_LIST_REQUEST";
export const DELETE_CURTAILMENT_TIMING_LIST_SUCCESS =
    "@@curtailmentTiming/DELETE_CURTAILMENT_TIMING_LIST_SUCCESS";
export const DELETE_CURTAILMENT_TIMING_LIST_FAILURE =
    "@@curtailmentTiming/DELETE_CURTAILMENT_TIMING_LIST_FAILURE";

export function deleteCurtailmentTimingList(id = "", organisation) {
    return async (dispatch) => {
        if (!(id && organisation)) return;
        dispatch({
            type: DELETE_CURTAILMENT_TIMING_LIST_REQUEST,
        });
        try {
            let url = "/showdata";
            if (id && organisation) {
                url = `${url}?project_id=${id}&table_name=in_curtailment_timing&organisation=${organisation}`;
            }

            const response = await API.del("usimAPI", url);
            dispatch({
                type: DELETE_CURTAILMENT_TIMING_LIST_SUCCESS,
                payload: response,
            });
            dispatch(getProjectDetails(id));
        } catch (error) {
            dispatch({
                type: DELETE_CURTAILMENT_TIMING_LIST_FAILURE,
                payload: error?.response?.data,
            });
        }
    };
}

export const UPLOAD_CURTAILMENT_TIMING_DATA_REQUEST =
    "@@curtailmentTiming/UPLOAD_CURTAILMENT_TIMING_DATA_REQUEST";
export const UPLOAD_CURTAILMENT_TIMING_DATA_SUCCESS =
    "@@curtailmentTiming/UPLOAD_CURTAILMENT_TIMING_DATA_SUCCESS";
export const UPLOAD_CURTAILMENT_TIMING_DATA_FAILURE =
    "@@curtailmentTiming/UPLOAD_CURTAILMENT_TIMING_DATA_FAILURE";

export function uploadCurtailmentTimingData(
    id = "",
    organisation,
    file,
    columnMappings,
    callback
) {
    return async (dispatch) => {
        if (!id) return;
        dispatch({
            type: UPLOAD_CURTAILMENT_TIMING_DATA_REQUEST,
        });
        try {
            let url = "/presignedurl";
            if (id) {
                url = `${url}?project_id=${id}&table_name=in_curtailment_timing`;
            }

            if (!isEmpty(columnMappings)) {
                url = `${url}&column_mappings=${encodeURI(
                    JSON.stringify(columnMappings)
                )}`;
            }

            const response = await API.get("usimAPI", url);

            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": file.type },
                body: file,
            };
            await fetch(response.uploadURL, requestOptions).then((response) => {
                if (response.status === 200) {
                    toast.success("Data uploaded successfully!");
                    setTimeout(() => {
                        dispatch(getCurtailmentTimingList(id, organisation));
                    }, 2000);
                    callback({
                        success: true,
                    });
                } else {
                    dispatch({
                        type: UPLOAD_CURTAILMENT_TIMING_DATA_FAILURE,
                        payload: {
                            message: "Upload data failed.",
                        },
                    });
                }
            });

            dispatch({
                type: UPLOAD_CURTAILMENT_TIMING_DATA_SUCCESS,
                payload: response,
            });
        } catch (error) {
            dispatch({
                type: UPLOAD_CURTAILMENT_TIMING_DATA_FAILURE,
                payload: error?.response?.data,
            });
        }
    };
}
