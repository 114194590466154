import {
    GET_DEMAND_RESPONSE_EV_UPTAKE_DOWNLOAD_TEMPLATE_REQUEST,
    GET_DEMAND_RESPONSE_EV_UPTAKE_DOWNLOAD_TEMPLATE_SUCCESS,
    GET_DEMAND_RESPONSE_EV_UPTAKE_DOWNLOAD_TEMPLATE_FAILURE,
    GET_DEMAND_RESPONSE_EV_UPTAKE_LIST_REQUEST,
    GET_DEMAND_RESPONSE_EV_UPTAKE_LIST_SUCCESS,
    GET_DEMAND_RESPONSE_EV_UPTAKE_LIST_FAILURE,
    DELETE_DEMAND_RESPONSE_EV_UPTAKE_LIST_REQUEST,
    DELETE_DEMAND_RESPONSE_EV_UPTAKE_LIST_SUCCESS,
    DELETE_DEMAND_RESPONSE_EV_UPTAKE_LIST_FAILURE,
    UPLOAD_DEMAND_RESPONSE_EV_UPTAKE_DATA_REQUEST,
    UPLOAD_DEMAND_RESPONSE_EV_UPTAKE_DATA_SUCCESS,
    UPLOAD_DEMAND_RESPONSE_EV_UPTAKE_DATA_FAILURE,
} from "actions/vehicle/demandResponseEVUptake";
import { uSimError } from "utils/override";

import { convertAndDownload } from "utils/csv";

const initialState = {
    loading: false,
    uploading: false,
    list: [],
    templateLoading: false,
    template: [],
};

export default function demandResponseEVUptake(
    state = initialState,
    action
) {
    const { type, payload } = action;

    switch (type) {
        case GET_DEMAND_RESPONSE_EV_UPTAKE_DOWNLOAD_TEMPLATE_REQUEST: {
            return {
                ...state,
                templateLoading: true,
                template: [],
            };
        }

        case GET_DEMAND_RESPONSE_EV_UPTAKE_DOWNLOAD_TEMPLATE_SUCCESS: {
            convertAndDownload(payload, "DemandResponseEVUptake");
            return {
                ...state,
                templateLoading: false,
                template: payload,
            };
        }

        case GET_DEMAND_RESPONSE_EV_UPTAKE_DOWNLOAD_TEMPLATE_FAILURE: {
            uSimError(payload?.message || "Something went wrong");
            return {
                ...state,
                templateLoading: false,
                template: [],
            };
        }

        case GET_DEMAND_RESPONSE_EV_UPTAKE_LIST_REQUEST:
        case DELETE_DEMAND_RESPONSE_EV_UPTAKE_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case UPLOAD_DEMAND_RESPONSE_EV_UPTAKE_DATA_REQUEST: {
            return {
                ...state,
                uploading: true,
            };
        }

        case GET_DEMAND_RESPONSE_EV_UPTAKE_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: payload.map((it, i) => {
                    return {
                        ...it,
                        id: i,
                    };
                }),
            };
        }

        case DELETE_DEMAND_RESPONSE_EV_UPTAKE_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }

        case UPLOAD_DEMAND_RESPONSE_EV_UPTAKE_DATA_SUCCESS: {
            return {
                ...state,
                uploading: false,
            };
        }

        case GET_DEMAND_RESPONSE_EV_UPTAKE_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                list: [],
            };
        }

        case DELETE_DEMAND_RESPONSE_EV_UPTAKE_LIST_FAILURE: {
            uSimError(payload?.message || "Something went wrong");
            return {
                ...state,
                loading: false,
            };
        }

        case UPLOAD_DEMAND_RESPONSE_EV_UPTAKE_DATA_FAILURE: {
            uSimError(payload?.message || "Something went wrong");
            return {
                ...state,
                uploading: false,
            };
        }

        default:
            return state;
    }
}
