import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Content, MFASetupModal, Sidebar } from "components";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { isNil } from "lodash";
import InactivityModal from "pages/Session/InactivityModal";

const wrapperStyle = () => {
  return {
    display: "flex",
    minHeight: "100vh",
  };
};

const pageStyle = () => {
  return {
    display: "flex",
    flexDirection: "column",
  };
};

const AuthLayout = () => {
  const navigate = useNavigate();

  const [contentWidth, setContentWidth] = useState("100%");

  const [openMfaSetup, setOpenMfaSetup] = useState(false);

  const session = useSelector((state) => state.session.session);
  const loading = useSelector((state) => state.session.loading);
  const preferredMfa = useSelector((state) => state.session.preferredMfa);
  const preferredMfaLoading = useSelector(
    (state) => state.session.preferredMfaLoading
  );

  useEffect(() => {
    if (isNil(session) && !loading) {
      navigate("/");
    }
  }, [session]);

  useEffect(() => {
    if (
      !preferredMfaLoading &&
      !["SOFTWARE_TOKEN_MFA", "TOTP"].includes(preferredMfa)
    ) {
      setOpenMfaSetup(true);
    } else {
      setOpenMfaSetup(false);
    }
  }, [preferredMfaLoading, preferredMfa]);

  const onOpenChange = (open) => {
    setContentWidth(open ? "calc(100vw - 320px)" : "100%");
  };

  return (
    <Box sx={pageStyle}>
      <Box sx={wrapperStyle}>
        <Sidebar onOpenChange={onOpenChange} />
        <Content width={contentWidth}>
          <Outlet />

          <InactivityModal />
        </Content>

        {openMfaSetup && (
          <MFASetupModal
            open={openMfaSetup}
            onClose={() => setOpenMfaSetup(false)}
          />
        )}
      </Box>
    </Box>
  );
};

export default AuthLayout;
