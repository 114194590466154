import { Box, Button, Typography } from "@mui/material";
import { Modal } from "components";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

const styles = () => {
  return {
    "& .timer-section": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      py: "40px",
    },
    "& .buttons": {
      display: "flex",
      justifyContent: "space-between",
      "& button": {
        width: "48%",
      },
      borderTop: "2px solid #D6DDEA",
      pt: "8px",
      mt: "4px",
    },
  };
};

const REMAINING = 120;

const events = ["mousemove", "mousedown", "keydown", "touchstart", "wheel"];

const InactivityModal = () => {
  const navigate = useNavigate();

  const [idle, setIdle] = useState(false);
  const [remaining, setRemaining] = useState(REMAINING);

  var interval = null;

  useEffect(() => {
    if (idle) {
      interval = setInterval(() => {
        setRemaining((prev) => {
          if (prev === 0) {
            onLogout();
          }
          return prev - 1;
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [idle]);

  const { reset } = useIdleTimer({
    timeout: 13 * 60 * 1000, // 13 minutes + 2 minutes remaining = 15 minutes
    onIdle: () => setIdle(true),
    debounce: 500,
    events,
  });

  const onClickContinue = () => {
    setIdle(false);
    reset();
    setRemaining(REMAINING);
    if (interval) {
      clearInterval(interval);
    }
  };

  const onLogout = () => {
    navigate("/logout");
    setIdle(false);
    reset();
  };

  const renderClock = () => {
    const min = Math.floor(remaining / 60);
    const remSec = remaining % 60;
    return `${min}:${remSec.toString().padStart(2, 0)}`;
  };

  return (
    <div>
      {idle && (
        <Modal
          top="20%"
          title="Your Session Will Expires Soon!"
          open={idle}
          blurBackground={true}
        >
          <Box sx={styles}>
            <Typography>
              You will automatically be logged out of uSim in:
            </Typography>
            <Box className="timer-section">
              <Typography variant="h1" fontSize="48px">
                {renderClock()}
              </Typography>
            </Box>
            <Box>
              <Typography align="center">
                Would you like to stay logged in?
              </Typography>
            </Box>
            <Box className="buttons">
              <Button variant="outlined" onClick={onLogout}>
                Logout
              </Button>
              <Button variant="contained" onClick={onClickContinue}>
                Keep Working
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default InactivityModal;
