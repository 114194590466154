import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Content, Sidebar } from "components";
import { Box } from "@mui/material";
import {
  ProjectHeader,
  ProjectPageContainer,
  ProjectSidebar,
} from "components";
import { getProjectDetails, clearProjectDetails } from "actions/projects";
import useProjectSidebar from "hooks/useProjectSidebar";
import { isNil } from "lodash";
import { getTabRoute, projectHeaderTab } from "constants/project";
import InactivityModal from "pages/Session/InactivityModal";

const wrapperStyle = () => {
  return {
    display: "flex",
    minHeight: "100vh",
  };
};

const pageStyle = () => {
  return {
    display: "flex",
    flexDirection: "column",
  };
};

const ProjectLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  useProjectSidebar();

  const [contentWidth, setContentWidth] = useState("100%");
  const [activeTab, setActiveTab] = useState(projectHeaderTab.upload);

  const session = useSelector((state) => state.session.session);
  const loading = useSelector((state) => state.session.loading);
  const showProjectSidebar = useSelector(
    (state) => state.projects?.showProjectSidebar
  );

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.includes(projectHeaderTab.simulations)) {
      setActiveTab(projectHeaderTab.simulations);
    } else if (pathname.includes(projectHeaderTab.scenarios)) {
      setActiveTab(projectHeaderTab.scenarios);
    } else {
      setActiveTab(projectHeaderTab.upload);
    }
  }, [location]);

  useEffect(() => {
    if (isNil(session) && !loading) {
      navigate("/");
    }
  }, [session]);

  useEffect(() => {
    if (id) {
      dispatch(getProjectDetails(id));
    }
    return () => {
      dispatch(clearProjectDetails);
    };
  }, [dispatch, id]);

  const onOpenChange = (open) => {
    setContentWidth(open ? "calc(100vw - 320px)" : "100%");
  };

  const onClickClose = () => {
    navigate(`/projects/details/${id}`);
  };

  const onChangeTab = (tab) => {
    navigate(getTabRoute(tab, id));
    setActiveTab(tab);
  };

  return (
    <Box sx={pageStyle}>
      <Box sx={wrapperStyle}>
        <Sidebar onOpenChange={onOpenChange} />
        <Content width={contentWidth}>
          <ProjectHeader
            activeTab={activeTab}
            onChangeTab={onChangeTab}
            onClickClose={onClickClose}
          />
          <ProjectPageContainer>
            {showProjectSidebar && <ProjectSidebar id={id} />}
            <Box
              sx={{
                width: showProjectSidebar ? "calc(100% - 264px)" : "100%", //264 sidebar
              }}
            >
              <Outlet />
              <InactivityModal />
            </Box>
          </ProjectPageContainer>
        </Content>
      </Box>
    </Box>
  );
};

export default ProjectLayout;
